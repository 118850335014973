import React from "react";
import Layout from "../components/Layout";

function AdaStatementPage() {
  return (
    <Layout>
      <iframe
        src={`https://customer-portal.audioeye.com/accessibility-statement.html?domain=brochure.virgingalactic.com/`}
        title="Accessibility Statement"
        style={{ width: "100%", height: "2400px", padding: "80px 0 0 80px" }}
        frameborder={1}
      ></iframe>
    </Layout>
  );
}

export default AdaStatementPage;
